import React from "react";
import { useEffect, useState, useContext } from "react";
import AlertDialogSlide from "../components/Modal";
import { auth } from "../firebase";
import { AppContext } from "../context/AppContext";
import { signOut } from "firebase/auth";
import { Link, useLocation, useNavigate } from "react-router-dom";
//import { Button } from "@mui/material";
//import axios from "axios";
import Job from "../components/Job";
import AnalyzedText from "../components/AnalyzedText";


export default function Dashboard() {
  // =========================================
  // ================ STATES =================
  // =========================================
  const [resData, setResData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  //const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const { setUser } = useContext(AppContext);
  //const { setUser, user } = useContext(AppContext);
  const navigate = useNavigate();
  const { state } = useLocation();

  // =========================================
  // ================ EFFECTS ==============
  // =========================================
  useEffect(() => {
    setResData(state.data);
    console.log(state.data);
  }, [state.data]);

  // =========================================
  // ================ HANDLERS ==============
  // =========================================

  // this function is used to fetch the previous or next page data
  // still not working due to invalid api key probably
  /*const fetchData = (url) => {
    setIsLoading(true);
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      })
      .then((data) => {
        setResData(data.company_response);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };*/

  const logout = () => {
    navigate("/");
    signOut(auth).then(setUser());
  };

  // =========================================
  // ================ RENDER ==============
  // =========================================

  return (
    <div className="w-full flex flex-col justify-center pt-32">
      <div className="w-full flex justify-end pr-10">
        <button
          onClick={() => logout()}
          className="text-black   text-xl px-3 py-1 cursor-pointer flex items-center gap-2"
        >
          Sign out
          <img className="w-[30px] h-[30px]" src="/images/logout.svg" alt="" />
        </button>
      </div>
      <Link
        to={"https://" + state.company.url}
        className="text-4xl text-center mb-10 font-bold"
        target="_blank"
      >
        {state.company.name}
      </Link>
      <AnalyzedText
        analyzed={state.analyzed}
        companyName={state.company.name}
      />
      {resData?.job?.length > 0 ? (
        <>
          <div className="p-4 flex justify-between flex-wrap gap-4 px-10 max-w-6xl m-auto">
            {resData?.job.map((el, index) => {
              return (
                <Job
                  key={index}
                  index={index}
                  job_title={el.job_title}
                  location={el.location}
                  job_url={el.job_url}
                  onClick={() => {
                    setSelectedData(el);
                    setOpen(true);
                  }}
                />
              );
            })}
          </div>
          {/* <div className=" flex items-center justify-center gap-4 my-4">
            <Button
              variant="contained"
              color="primary"
              onClick={() => fetchData(resData.previous_page_api_url)}
              disabled={!!!resData?.previous_page_no || isLoading}
            >
              {isLoading ? "Loading..." : "Previous"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => fetchData(resData.next_page_api_url)}
              disabled={!!!resData?.next_page_no || isLoading}
            >
              {isLoading ? "Loading..." : "Next"}
            </Button>
          </div> */}
        </>
      ) : (
        <p className="text-4xl text-center mb-10 font-bold">Data Not Found</p>
      )}
      <AlertDialogSlide
        open={open}
        setOpen={setOpen}
        selectedData={selectedData}
      />
    </div>
  );
}
