import React, { useContext, useState } from "react";
import * as XLSX from "xlsx";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import { AppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";

const OpenDoc = () => {
  const { setUser, user, setLoading } = useContext(AppContext);
  const [textData, setTextData] = useState([]);
  const [selected, setSelected] = useState("");
  const [formData, setFormData] = useState({
    start_date: "",
    end_date: "",
    news: false,
    annual_report: false,
    financial_statement: false,
    trigger_events: false,
    email_trigger_events: false,
    keywords: "",
    email: "",
  });
  const navigate = useNavigate();

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        const textContent = jsonData
          .map((row) =>
            row.filter((cell) => typeof cell === "string" && cell.trim() !== "")
          )
          .filter((row) => row.length > 0)
          .map((row) => row.map((s) => s.trim()).join(", "));
        textContent.shift();
        setTextData(textContent);
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const submitData = async () => {
    const newForm = formData;
    const companyURL = selected.split(",")[0];
    const companyName = selected.split(",")[1].trim();
    newForm.company_name = companyName;
    newForm.keywords = formData.keywords.split(/[,\s]+/);
    newForm.start_date = "2022-01-06";
    console.log(newForm);
    setLoading(true);
    fetch("https://contractstream-ccc.ey.r.appspot.com/process", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.accessToken}`,
      },
      body: JSON.stringify(newForm),
    })
      .then((res) => res.json())
      .then((data) => {
        navigate("/dashboard", {
          state: {
            data: data.company_response,
            company: {
              url: companyURL,
              name: companyName,
            },
            analyzed: data.analyzed_text,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
    setFormData({
      start_date: "",
      end_date: "",
      news: false,
      annual_report: false,
      financial_statement: false,
      email_trigger_events: false,
      keywords: "",
    });
  };

  const logout = () => {
    signOut(auth).then(setUser());
  };

  return (
    <div className="px-4 bg-gradient-to-t from-blue-300 to-blue-100 absolute top-0 bottom-0 left-0 right-0">
      <div className="py-8 flex justify-around items-center relative">
        <label
          htmlFor="file-input"
          className="p-3 border-2 border-[#1976D2] mt-4 rounded-2xl cursor-pointer hover:translate-y-1 duration-150 shadow-inner hover:shadow-[#1976D2] font-semibold text-xl"
        >
          Upload File
        </label>
        <input
          type="file"
          id="file-input"
          accept=".xlsx, .doc, .ods, .csv, .tsv"
          onChange={handleFileInputChange}
          className="hidden"
        />
        <button
          onClick={() => logout()}
          className="text-black absolute right-3  text-xl px-3 py-1 cursor-pointer flex items-center gap-2"
        >
          Sign out
          <img className="w-[30px] h-[30px]" src="/images/logout.svg" alt="" />
        </button>
      </div>
      {textData.length > 0 && (
        <div className="flex font-shippo max-h-[700px] ">
          <div className="w-3/6 max-h-[700px] overflow-y-scroll">
            <TableContainer
              component={Paper}
              className=" overflow-x-hidden shadow-none"
            >
              <Table aria-label="simple table" className="shadow-none">
                <TableHead>
                  <TableRow>
                    <TableCell className="w-full border-b-2 p-4 ">
                      <p className="font-bold text-2xl">Company Name</p>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {textData.map((company, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      className="cursor-pointer"
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        onClick={() => setSelected(company)}
                      >
                        <p
                          className={`font-bold ${
                            company === selected && "text-[#1976D2]"
                          } `}
                        >
                          {company}
                        </p>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="w-3/6 p-10 bg-white max-h-[700px] overflow-auto lg:overflow-visible">
            <p className="text-4xl semibold text-center font-shippo">
              {selected}
            </p>
            {selected && (
              <div className="py-10">
                <div className="flex gap-3 ">
                  <div className="w-full">
                    <p>Start date</p>
                    <TextField
                      id="start_date"
                      name="start_date"
                      className="w-full"
                      variant="outlined"
                      type="date"
                      value={formData?.start_date}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="w-full">
                    <p>End date</p>
                    <TextField
                      id="end_date"
                      name="end_date"
                      className="w-full"
                      variant="outlined"
                      type="date"
                      value={formData?.end_date}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-3 mt-3">
                  <TextField
                    id="keywords"
                    name="keywords"
                    variant="outlined"
                    label="Keywords"
                    value={formData?.keywords}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="flex flex-col py-5">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="news"
                        checked={formData?.news}
                        onChange={handleInputChange}
                      />
                    }
                    label="Research News"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="annual_report"
                        checked={formData?.annual_report}
                        onChange={handleInputChange}
                      />
                    }
                    label="Research Annual Report"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="financial_statement"
                        checked={formData?.financial_statement}
                        onChange={handleInputChange}
                      />
                    }
                    label="Research Financial Statements"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="email_trigger_events"
                        checked={formData?.email_trigger_events}
                        onChange={handleInputChange}
                      />
                    }
                    label="Email Trigger Events"
                  />
                  {formData.email_trigger_events && (
                    <TextField
                      id="email"
                      name="email"
                      label="Email Address"
                      variant="outlined"
                      value={formData.email || ""} // Stelle sicher, dass formData.email initialisiert ist
                      onChange={handleInputChange}
                      fullWidth
                      margin="normal"
                    />
                  )}
                </div>
                <Button variant="contained" onClick={() => submitData()}>
                  Share Updates
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default OpenDoc;
