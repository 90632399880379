import React from "react";
import Balancer from "react-wrap-balancer";

export default function AnalyzedText({ analyzed, companyName }) {
  //const isUrl =
  //  /^(?:(?:http|https|ftp):\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=%]+$/;
  return (
    <div className="text-center mb-4 p-4 max-w-6xl m-auto bg-gray-50 border border-slate-400">
      {/* update the condition to 'analyzed?.length' to view the analyzed text */}
      {false ? (
        analyzed
          ?.filter(({ text }) => text.trim().length)
          .map(({ text, url }) => {
            return (
              <a
                href={url}
                target="_blank"
                rel="noreferrer"
                className="text-blue-700 underline decoration-1 cursor-pointer"
              >
                {text}
              </a>
            );
          })
      ) : (
        <p>
          <Balancer>
            This is a placeholder text for {companyName}. We are working hard
            developing a tool that can do account research for you. We will be
            able to keep you up to date on current relevant news, initiatives &
            account activity for your accounts
          </Balancer>
        </p>
      )}
    </div>
  );
}
