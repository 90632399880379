import React from "react";
import { Link } from "react-router-dom";

export default function Job({ index, job_title, location, job_url, onClick }) {
  return (
    <div className=" bg-gray-50 w-full sm:w-[48%] lg:w-[32%] border rounded-md p-4 cursor-pointer hover:border-slate-400 duration-150">
      <p className=" mb-4">Job {index + 1}</p>
      <p
        className="text-md font-bold"
        //   onClick={onClick}
      >
        {job_title}
      </p>
      <p className=" text-slate-400 mb-4">{location}</p>
      <Link
        to={job_url}
        className=" text-sm mt-4 underline text-blue-700"
        target="_blank"
      >
        View the full job description
      </Link>
    </div>
  );
}
