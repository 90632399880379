import { initializeApp} from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API || "test",
  authDomain: "contractstream-ccc.firebaseapp.com",
  projectId: "contractstream-ccc",
  storageBucket: "contractstream-ccc.appspot.com",
  messagingSenderId: "862557219543",
  appId: "1:862557219543:web:e174f0ac263aa5b9999e92",
  measurementId: "G-L32EDHC2CZ",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
export {db, auth};
