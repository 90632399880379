import React, { useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { collection, doc, setDoc } from "firebase/firestore";

import { db } from "../firebase";
import { AppContext } from "../context/AppContext";
export default function SignUp() {
  const {setUser} = useContext(AppContext)

  const usersRef = collection(db, "users");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [phone, setPhone] = useState("");
  const [isValidPass, setIsValidPass] = useState(false);
  const navigate = useNavigate();
  const emailReg = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
  const password_validate = (pass) => {
    setPassword(pass);
    const re = {
      capital: /(?=.*[A-Z])/,
      length: /(?=.{7,40}$)/,
      specialChar: /[-/:-@[-`{-~]/,
      digit: /(?=.*[0-9])/,
    };
    setIsValidPass(
      re.capital.test(pass) &&
        re.length.test(pass) &&
        re.specialChar.test(pass) &&
        re.digit.test(pass)
    );
  };

  const handleSubmit = async () => {
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        if(user){
          try {
            setUser(user)
            setDoc(doc(usersRef, user.uid), {
              email, name, surname, phone, uid: user.uid
            });
            navigate('/')
          } catch (e) {
            console.error("Error adding document: ", e);
          }
        }
      })
      .catch((error) => {
        //const errorCode = error.code;
        //const errorMessage = error.message;
      });

     
  };
  return (
    <div className="w-full flex justify-center absolute top-0 bottom-0  bg-gradient-to-t from-cyan-500 to-blue-500">
      <div className="w-[600px] flex flex-col justify-center p-10 py-2 gap-10 border-2 rounded-2xl my-40 bg-[#ffffffda]">
        <p className="text-4xl text-center font-bold text-[#1976D2]">
          Create An Account
        </p>
        <div className="flex gap-3">
          <div className="flex flex-col gap-3 w-full">
            <TextField
              onChange={(e) => {
                setName(e.target.value);
              }}
              label="Name"
              variant="outlined"
              type="text"
            />
            <TextField
              onChange={(e) => {
                setSurname(e.target.value);
              }}
              label="Last Name"
              variant="outlined"
              type="text"
            />
            <TextField
              error={!emailReg.test(email) && email !== ""}
              label="Email"
              variant="outlined"
              type="text"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className="flex flex-col gap-3 w-full">
            <TextField
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              label="Phone Number"
              variant="outlined"
              type="text"
            />
            <TextField
              error={!isValidPass && password !== ""}
              label="Password"
              variant="outlined"
              onChange={(e) => password_validate(e.target.value)}
              type="password"
            />
            <TextField
              error={confirm !== password && confirm !== ""}
              disabled={!isValidPass}
              label="Confirm Password"
              variant="outlined"
              onChange={(e) => setConfirm(e.target.value)}
              type="password"
            />
          </div>
        </div>
        <div className="flex justify-center ">
          <div className="w-[50%] flex-col flex gap-2">
            <Button
              className="w-full"
              onClick={() => handleSubmit()}
              variant="contained"
              color="primary"
            >
              Sign up
            </Button>
            <Button className="w-full" variant="contained">
              Sign in with Google
            </Button>
          </div>
        </div>
        <p className="text-center text-lg">
          Already have an account?{" "}
          <span
            className="text-[#03a9f4] hover:cursor-pointer"
            onClick={() => navigate("/")}
          >
            {" "}
            Sign in
          </span>
        </p>
      </div>
    </div>
  );
}
