import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login";
import SignUp from "./Pages/SignUp";
import { AuthContextProvider } from "./context/AuthContext";
import OpenDoc from "./Pages/OpenDoc";
import { useEffect, useState } from "react";
import { AppContext } from "./context/AppContext";
import { onAuthStateChanged } from "firebase/auth";
import CircularProgress from "@mui/material/CircularProgress";
import { auth } from "./firebase";
import Dashboard from "./Pages/Dashboard";

function App() {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      }
      setLoading(false);
    });

    return ()=>{
      listen()
    }
  }, []);
  
  const PrivateRoute = () =>
    loading ? (
      <div className="absolute z-10 top-0 right-0 bottom-0 left-0 flex justify-center items-center">
        <CircularProgress />
      </div>
    ) : user ? (
      <Routes>
        <Route path="/" element={<OpenDoc />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    ) : (
      <Routes >
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
      </Routes>
    );

  return (
    <AuthContextProvider>
      <AppContext.Provider value={{ setUser, user, setLoading }}>
        <BrowserRouter>
          <PrivateRoute />
        </BrowserRouter>
      </AppContext.Provider>
    </AuthContextProvider>
  );
}

export default App;
