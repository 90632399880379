import React, { useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import {  UserAuth } from "../context/AuthContext";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
//import { db } from "../firebase";
//import { doc, getDoc } from "firebase/firestore";
import { AppContext } from "../context/AppContext";
export default function Login() {
  const {setUser} = useContext(AppContext)

  const { googleSignIn } = UserAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isValidPass, setIsValidPass] = useState(false);
  const navigate = useNavigate();
  const emailReg = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
  const password_validate = (pass) => {
    setPassword(pass);
    const re = {
      capital: /(?=.*[A-Z])/,
      length: /(?=.{7,40}$)/,
      specialChar: /[-/:-@[-`{-~]/,
      digit: /(?=.*[0-9])/,
    };
    setIsValidPass(
      re.capital.test(pass) &&
        re.length.test(pass) &&
        re.specialChar.test(pass) &&
        re.digit.test(pass)
    );
  };

  const handleSubmit =  () => {
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        setUser(user)
        user.getIdToken()
      })
      .catch((error) => {
            console.log(error)
      });
  };


  /*const getUserData = async (uid) => {
      const docRef = doc(db, "users", uid);
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          return docSnap.data();
        } else {
          console.log("No such document!");
          return null;
        }
      } catch (error) {
        console.error("Error getting document:", error);
        return null;
      }
  };*/

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="w-full flex justify-center absolute top-0 bottom-0  bg-gradient-to-t from-cyan-500 to-blue-500">
      <div className="w-[600px] flex flex-col justify-center p-10 gap-10 border-2 rounded-2xl my-40 bg-[#ffffffda]">
        <p className="text-4xl text-center font-bold text-[#1976D2]">
          Login to Your Account
        </p>
        <TextField
          error={!emailReg.test(email) && email !== ""}
          label="Email"
          variant="outlined"
          type="text"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <TextField
          error={!isValidPass && password !== ""}
          label="Password"
          variant="outlined"
          onChange={(e) => password_validate(e.target.value)}
          type="password"
        />
        <div className="flex justify-center ">
          <div className="w-[50%] flex-col flex gap-2">
            <Button
              className="w-full"
              onClick={() => handleSubmit()}
              variant="contained"
              color="primary"
            >
              Sign in
            </Button>
            <Button
              className="w-full"
              variant="contained"
              onClick={() => handleGoogleSignIn()}
            >
              Sign in with Google
            </Button>
          </div>
        </div>
        <p className="text-center text-lg">
          Don't have an account?{" "}
          <span
            className="text-[#03a9f4] hover:cursor-pointer"
            onClick={() => navigate("/signup")}
          >
            Sign up
          </span>
        </p>
      </div>
    </div>
  );
}
