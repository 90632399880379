import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({open, setOpen, selectedData}) {



  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className='p-6 w-[500px]'>
            <div className="flex justify-between items-center">
            <p className="text-4xl font-bold">{selectedData.job_title}</p>
            <Button onClick={handleClose}>
                Close
            </Button>
            </div>
            <div>
                <p className="text-2xl font-bold mt-5">Job URL</p>
                <a href={selectedData.company_url} target="_blank" rel="noreferrer" className="text-blue-700 underline decoration-1">{selectedData.job_url}</a>
            </div>
            {/* <div>
                <p className="text-2xl font-bold mt-5">Company URL</p>
                <a href={selectedData.company_url} target="_blank" rel="noreferrer" className="text-blue-700 underline decoration-1">{selectedData.company_url}</a>
            </div> */}
            <div className='flex justify-between items-center mt-5'>
              <p>{selectedData.list_date}</p>
              <div className='flex items-center gap-1'>
                  <img src="/images/location.png" className='w-[20px] h-[20px]' alt="" />
                  <p>{selectedData.location}</p>
              </div>
            </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
}